.icon_fa {
    &:before {
        position: absolute;
        font-family: FontAwesome;
        color: $brand-primary;
        transform: translateX(-150%);
        -webkit-transform: translateX(-150%);
        font-size: $font-size-base;
    }
    
    &_remove {
        &:before {            
            content: "\f00d";
            font-size: $font-size-base + 2;
            transform: translate(-150%, -3px);
            -webkit-transform: translateY(-150%, -3px);
            color: #d11919;
        }
    }
    &_add {
        &:before {
            color: #1da510;
            content: "\f067";
        }
    }
    &_edit {
        &:before {
            content: "\f040";
        }
    }
    &_bypass {
        &:before {
            content: "\f074";
        }
    }
    &_help {
        &:before {
            font-family: FontAwesome;
            color: $brand-secondary;
            font-size: $font-size-base;
            content: "\f128";
        }
    }
}