.#{$pre}table {
    display: table;
    margin: 0;
    width: 100%;

    &__row {
        display: table-row;
    }

    &__cell {
        display: table-cell;
        vertical-align: middle;

        &--top {
            vertical-align: top;
        }

        &--bottom {
            vertical-align: bottom;
        }
        
        &--small {
            width: 95px;
        }
        
        &--no-stretch {
            width: 1%;
            white-space: nowrap;
        }
        
        &--text-side-padding {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.fixed-table {
    table-layout: fixed;
}