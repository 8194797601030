.container-fluid {
    padding-bottom: 20px;
}

.row {
    &--thin {
        @include margin(null (-6px));

        > div {
            @include padding(null 6px);
        }
    }
}
