html,
body {
    height: 100%;
    min-width: $w-page-min;
}

body {
    font-weight: $fw-base;
    height: 100%;
    text-align: center;
    letter-spacing: $fw-letter-spacing;

    &.popup-visible {
        overflow: hidden;
    }
}

svg {
    display: inline-block;
    fill: currentcolor;
    @include size(25px, 25px);
}
