.side-menu {
    overflow: hidden;

    &__overlay {
        background: $bg-side-menu-overlay;
        z-index: ($z-side-menu - 1);
        @include opacity(0);
        @include position(absolute, 0 0 0 0);
        @include transition(opacity $time-side-menu ease-in-out);
        @include size(0, 0);

        .opened & {
            @include opacity($op-side-menu-overlay);
            @include size(auto, auto);
        }
    }

    &__content {
        background-color: $bg-side-menu;
        color: $c-side-menu;
        overflow-x: hidden;
        overflow-y: auto;
        width: 0;
        z-index: $z-side-menu;
        @include position(absolute, 0 null 0 0);
        @include transition(width $time-side-menu ease-in-out);

        @media (max-width: $screen-xs-max) {
            position: fixed
        }

        .opened & {
            width: $w-side-menu;
        }
    }

    &__menu {
        width: $w-side-menu;
    }
}
