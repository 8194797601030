.frame {
    min-height: 100%;
    max-width: $w-page-max;
    position: relative;
    text-align: left;
    @include box-shadow(0 0 3px $gray-light);
    @include margin(null auto);
    @include padding(null 0);

    @media (max-width: $screen-xs-max) {
        padding-top: $h-header;
    }

    &.auth {
        padding-top: 0;
    }

    &.inner {
        background-color: $bg-light;
    }

    .content {
        @include padding($p-content 0 0 0);
    }

    &.popup-visible,
    .popup-visible & {
        overflow: hidden;
        position: relative;
        height: 100%;

    }

    &--dark.inner {
        background-color: transparent;
    }
}

.position_relative {
    position: relative;
}