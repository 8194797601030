.auth {
    background-color: $bg-auth;
    @include padding(null 10px);

    &__header {
        text-align: center;
    }

    a {
        &:hover,
        &:active,
        &:active:hover,
        &:focus {
            text-decoration: none;
        }
    }
    
    &-groupbox {
        border: #888 solid 1px;
        border-radius: 20px;
        padding: 10px;
    }
}

.eulaCheckbox {
    margin-bottom: 10px;
}

.google {
    &Button {
        // Kolkas nieko
    }

    &Icon {
        display: inline-block;
        margin-top: -2px;
        margin-left: -21px;
        
        &-mobile {
            svg {
                height: 33px;
                width: 33px;
            }
        }
    }

    &Text {
        // Kolkas nieko
    }
}