.status-slider {
    border-radius: $br-status-slider;
    padding: $p-status-slider;
    color: $c-status-slider;
    display: inline-block;
    line-height: $lh-status-slider;
    text-align: center;
    @include size($w-status-slider, $h-button);

    label & {
        cursor: pointer;
        font-size: $font-size-base;
    }

    input {
        display: none;
    }

    &:hover,
    &:active,
    &:active:hover,
    &:focus {
        color: $c-status-slider;
        text-decoration: none;
    }

    &:before {
        border-radius: 50%;
        content: '';
        background-color: $c-status-slider;
        display: none;
        @include size($lh-status-slider, $lh-status-slider);
    }

    &--on {
        background-color: $bc-status-slider-on;

        &:before {
            display: inline-block;
            float: right;
        }
    }
    &--off {
        background-color: $bc-status-slider-off;

        &:before {
            display: inline-block;
            float: left;
        }
    }

    > span {
        display: none;
        @include padding(null 5px);
    }

    &__label-on {
        .status-slider--on & {
            display: inline-block;
        }
    }
    &__label-off {
        .status-slider--off & {
            display: inline-block;
        }
    }
}
