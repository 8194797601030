.list {
    overflow: auto;
    @include margin((-$p-content) (-$grid-gutter-width/2) $p-content);

    .#{$pre}table {
        &__row {
            > a {
                display: table;
                width: 100%;

                &:hover,
                &:active,
                &:active:hover,
                &:focus {
                    background-color: $bg-xlight-hover;
                    text-decoration: none;
                }
            }
        }
        &__cell {
            border-bottom: 1px solid $body-bg;
            @include padding(10px);

            &.list {
                &__icon {
                    @include padding(14px null null 15px);
                }
                &__title {
                    @include padding(null null null 5px);

                    &--no-icon {
                        padding-left: 30px;
                    }
                }
                &__header {
                    @include padding(12px 10px 17px 10px);
                }
                &__time,
                &__action {
                    padding-right: 20px;
                }
            }
            &--no-bottom {
              border-bottom: none;
            }
            &--single-line {
                width: 100%;
                position: absolute;
            }
            &--left-padding {
                padding-left: 50px;
            }
        }
    }

    &__icon {
        color: $brand-primary;
        width: 18%;

        svg {
            @include size(45px, 35px);
        }

        .super-admin-icon {
            font-style: normal;
            font-size: 19px;
            line-height: 1;
            margin-top: -8px;
            margin-left: -2px;
            @include position(absolute, null null null null);
        }
        
        &.system-index {
            width: 70px;
        }
        &--user {
            min-width: 70px;
        }
    }

    &__title {

        .h1 {
            @include margin(0);
        }
    }

    &__sub-title {
        line-height: 1.2;
        @include margin(6px null null null);
        color: $gray-light;

        &--limited {
            max-width: $w-list-limit;
            @include text-overflow();

            @media (max-width: $wp-list-limit-md) {
                max-width: $w-list-limit-md;
            }

            @media (max-width: $wp-list-limit-xs) {
                max-width: $w-list-limit-xs;
            }
        }

        + .list__sub-title {
            margin-top: 0;
        }
    }

    &__time,
    &__action {
        text-align: right;
    }
    &__menu {
        text-align: right;
        position: relative;
        &--small {
            width: 95px;
        }
        &--medium {
            width: 15%;
            min-width: 115px;
        }
    }

    span.btn--small {

        &:hover {
            cursor: default;
        }

        &.btn-primary:hover {
            color: $btn-primary-color;
            background-color: $btn-primary-bg;
            border-color: $btn-primary-border;
        }

        &.btn-danger:hover {
            color: $btn-danger-color;
            background-color: $btn-danger-bg;
            border-color: $btn-danger-border;
        }

    }

    .list__item-disabled {
        > .list__icon, > .list__title, > .list__action {
            @include opacity(0.5);            
        }

        span.btn-danger,
        span.btn-danger:hover {
            background-color: $gray-light;
            border-color: $gray-light;
        }
    }

    &--areas {
        @include margin(0 (-$grid-gutter-width/4) $p-content);
        overflow: visible;

        .popup.popup--small {
            position: fixed;
        }

        ul {
            @include clearfix;
        }
    }

    &__areas-item {
        float: left;
        @include padding(5px 10px 15px);
        width: 50%;

    }

    &__top-title {
        border-bottom: 1px solid $body-bg;
        text-align: center;
        @include margin(0 -20px 15px);

        .h1 {
            @include margin(0);
            @include padding(0 25px 15px 25px);
        }
    }
    &__item--selectable {
        cursor: pointer;
        &:hover {
            color: #003d5b;
            .h1 {
                color: #003d5b;
            }
            text-decoration: underline;
        }
    }
    &__selected-user {
        background-color: $brand-primary;
        svg {
            color: white;
        }

        .list__title {

            .h1 {
                color: $bg-light;
            }
        }

        .list__title{
            color: $bg-light;
        }
        &:hover {
            color: $bg-light;
            .h1 {
                color: $bg-light;
            }
            text-decoration: underline;
        }
    }
    &__select-user {
        &:hover {
            background-color: $bg-light-hover;
        }
    }

    &__status-online {
        display: block;
        max-width: 100%;
        font-size: 12px;
        color: #3c763d;
        font-weight: bold;
    }

    &__status-offline {
        display: block;
        max-width: 100%;
        font-size: 12px;
        color: #f1204c;
        font-weight: bold;
    }
    
    &__with-overflow {
        overflow: visible;
    }
    
    &__check {
        padding-left: 2px !important;
        padding-right: 2px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    &__input {
        > input {
            width: auto;
        }
    }
}

.deviceUserItem.master {
    background-color: #F6F6F6;
}

.list__title {
    &--ellipsis-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        >.h1 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.flex-table {
    display: flex;
}

.listbox {
    border: 1px solid $brand-primary;
    border-radius: 7px;
    cursor: pointer;
    
    &--item {
        border-bottom: 1px solid $gray-light;
        padding: 5px 10px;
        
        &:last-child {
            border-bottom: none;
            padding: 5px 10px;
        }
        
        &:hover {
            font-weight: bold;
        }
        
        &__selected {
            background-color: $brand-primary;
            color: $brand-secondary;
        }
    }
    
    &__max100 {
        max-height: 100px;
    }
    
    &__max200 {
        max-height: 200px;
    }
    
    &__max300 {
        max-height: 300px;
    }
}