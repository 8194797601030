.popup {
    display: none;
    min-width: $w-page-min;
    overflow: auto;
    z-index: $z-popup;
    @include position(absolute, 0 0 0 0);

    &--full {
        background-color: $bg-popup;
        @include padding(30px 10px 0);

        .h1 {
            @include margin(0 0 20px 0);
        }

        .popup {
            &__content {
                height: 80%;
                overflow: auto;
                @include padding(0 20px);

                @media (max-height: 500px) {
                    height: 72%;
                }

                &--full {
                    height: 100%;
                }
            }

            &__footer {
                height: 17%;
                @include position(absolute, null 0 0 0);

                @media (max-height: 500px) {
                    height: 25%;
                }
            }

            &__footer-content {
                @include position(absolute, null 0 30px 0);
                @include padding(0 30px);
            }
        }
    }

    &--small {
        z-index: $z-popup;

        .h1 {
            text-align: center;
            text-transform: uppercase;
            @include margin(0 0 20px 0);

            &.no-transform {
                text-transform: none;
            }
        }

        .popup {
            &__overlay {
                background-color: $bg-popup-overlay;
                @include position(absolute, 0 0 0 0);
            }

            &__content {
                background-color: $bg-popup;
                border-radius: $border-radius-base;
                max-height: 98%;
                overflow: auto;
                max-width: 450px;
                min-width: 305px;
                width: 85%;
                @include padding(25px 20px);
                @include position(absolute, 50% null null 50%);
                @include transform(translateY(-50%) translateX(-50%));
                -webkit-transform: translate(-50%,-50%);
            }
        }
    }

    &--notification {
        .popup {
            &__content {
                text-align: center;

                .h1 {
                    margin-bottom: 15px;
                }

                p {
                    font-size: $font-size-large;
                    line-height: $lh-popup-notification;
                    margin-bottom: 20px;
                }

                .btn {
                    font-size: $font-size-large;
                    min-width: 125px;
                }
            }
        }
    }

    &--icon {
        .popup {
            &__content {
                overflow: visible;
                padding-top: $pt-popup-notification;

                &:before {
                    background-color: $bg-popup-notification-icon;
                    background-size: auto $w-popup-notification-icon-icon;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    border: $bdw-popup-notification-icon solid $bdc-popup-notification-icon;
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    z-index: $z-popup;
                    @include margin((-$w-popup-notification-icon / 2) null null (-$w-popup-notification-icon / 2));
                    @include size($w-popup-notification-icon, $w-popup-notification-icon);
                    @include position(absolute, 0 null null 50%);
                }
            }
        }
    }

    &--react {
        .popup {
            &__content {
                &:before {
                    background-image: url('#{$images}/svg/icon_notification-react.svg');
                    background-size: auto $w-popup-notification-icon-icon-danger;
                }

                .h1 {
                    color: $brand-danger;
                }
            }
        }
    }

    &--error {
        .popup {
            &__content {
                &:before {
                    background-image: url('#{$images}/svg/icon_notification-not_found.svg');
                }

                .h1 {
                    color: $brand-danger;
                }
            }
        }
    }

    &--success {
        .popup {
            &__content {
                &:before {
                    background-image: url('#{$images}/svg/icon_notification-success.svg');
                }

                .h1 {
                    color: $brand-primary;
                }
            }
        }
    }

    &--loading {
        svg {
            @include size(45px, 45px);
            animation: spin 1s linear infinite;
            &#progress-bar {
                animation: initial !important;
                @include size(65px, 65px);
            }
        }
    }

    &--preloader {
        position: fixed;

        .popup {
            &__content {
                background-color: transparent;
                color: #fff;
                text-align: center;
                min-width: 0;
                max-width: 100%;
                overflow: hidden;
                @include padding(1px);
                @include size(auto, auto);

                svg {
                    @include size(45px, 45px);
                    animation: spin 1s linear infinite;
                }
                
                #slow_connection_text {
                    margin-top: 10px;
                    font-size: 16px;
                }
            }
        }
    }
    
    &__checkbox-row {
        margin-bottom: 10px;
        text-align: left;
    }
}

@-webkit-keyframes spin {
    0%   {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
