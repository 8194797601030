/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Aug 31, 2017, 2:33:58 PM
    Author     : PC
*/

.error {
    &-text {
        font-size: 40px;
        margin-bottom: 40px;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
        font-family: 'Roboto';
    }
    
    &-back {
        margin-bottom: 20px;
    }
    
    &-container {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        vertical-align: middle;
    }

    &-content {
        text-align: center;
        display: inline-block;
    }
    
    &-common {
        color: $brand-secondary;
        background-color: $brand-danger;
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    }
    
    &-highlight {
        background-color: $brand-danger-light;
    }
}