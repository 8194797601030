.header {
    background: $bg-header;
    height: $h-header;
    position: relative;
    z-index: $z-header;
    @include padding(13px null);
    @include box-shadow($bxsh-header);

    @media (max-width: $screen-xs-max) {
        @include position(fixed, 0 0 null 0);
    }

    &--sub {
        @media (max-width: $screen-xs-max) {
            @include position(relative, 0 0 0 0);
        }
        z-index: 0;
        background-color: #f4f4f4;
    }

    .inner & {
        @include box-shadow(none);
        border-bottom: 1px solid $body-bg;

        &.no-border {
            border-bottom: 0;
        }
    }

    .frame--dark & {
        border-bottom: 0;
        @include box-shadow($bxsh-header);
    }
}
