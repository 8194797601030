.carousel {
    height: 100%;
    overflow: hidden;

    .help & {
        @include position(absolute, 0 0 0 0);
    }

    &__leaf-text {
        height: 75%;
        @include padding(null 20px);
        @include position(absolute, 10% 10px null 10px);

        @media (max-height: 600px) {
            height: 60%;
            top: 15%;
        }

        .h1 {
            margin-bottom: 20px;
        }
    }

    &__leaf-icon {
        @include padding(80px null 60px 40px);

        @media (max-height: 600px) {
            @include padding(30px null 0);
        }

        @media (max-height: 520px) {
            @include padding(0);
        }

        svg {
            @include size(auto, 135px);

            @media (max-height: 520px) {
                @include size(auto, 80px);
            }
        }
    }

    &__leaf-image {
        padding-top: 25px;

        img {
            border: 1px solid $body-bg;
            border-radius: $border-radius-base;
            max-height: 100%;
            max-width: 100%;
            overflow: hidden;
        }
    }

    &__leaf-navigation {
        text-align: center;
        @include padding(null 30px 30px);
        @include position(absolute, null 0 0 0);

        .content & {
            @include padding(null 0);
        }

        .carousel__leaf--no-system + & {
            @include padding(null 30px);
        }

        .row--thin .btn {
            @include padding(null 5px);
        }

        &--static {
            @include position(static, null null null null);
        }
    }

    &__leaf {
        min-height: 100%;
        text-align: center;
        @include position(absolute, 0 100% null -100%);
        @include transition(left 1s ease, right 1s ease);

        &.active {
            left: 0;
            right: 0;

            + .carousel__leaf {
                left: 100%;
                right: -100%;
            }
        }

        &.previous {
            left: -100%;
            right: 100%;
        }

        &.next {
            left: 100%;
            right: -100%;
        }

        &--no-system {
            .carousel {
                &__leaf-icon {
                    padding-left: 20px;
                }
            }
        }
    }

    &__paging {
        text-align: center;
        @include position(absolute, 35px 0 null 0);
    }

    &__page {
        background-color: $body-bg;
        border-radius: 50%;
        display: inline-block;
        @include margin(0 5px);
        @include size(19px, 19px);

        &.active {
            background-color: $brand-primary;
        }
    }
}
