.file-field-override {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    
    &[readonly] {
        @extend .file-field-override;
        background-color: #fff;
    }
}
.logo-file-picker-container {
    position: relative;
}

.fileSelectorButton {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    padding: 7px 10px 6px 10px !important;
}

.text-required {
    color: $brand-danger;
}

.text-bold {
    font-weight: bold;
}

.ipcom-name-container {
    display: flex;
}

.ipcom-job-id {
    margin-top: 2px;
    line-height: 29px;
    height: 30px;
    border: 1px $brand-primary solid;
    background-color: $brand-primary;
    color: $brand-secondary;
    border-radius: 16px;
    width: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.ipcom-name-text {
    flex: 1;
    margin-left: 5px;
}