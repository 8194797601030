/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Sep 27, 2017, 11:01:34 AM
    Author     : PC
*/

.cam {
    &__list {
        &-snapshot {
            width: 180px;
            height: 100px;
            float: left;
        }
    }
}

.list {
    &__camera-item {
        float: left;
        @include padding(5px 10px 15px);
        width: 210px;
    }
    
    &__camera-item-cell {
        @include padding(5px 10px 15px);
        width: 210px;
    }
    
    &__camera-mini {
        margin: 5px !important;
    }
    
    &__camera-stream {
        float: left;
        width: 50%;
        padding-right: 1px;
        margin-bottom: 1px;
        
        &-mobile {
            float: left;
            width: 100%;
            margin-bottom: 1px;
        }
    }
    
    &__camera-button-container {
        width: 100px;
    }
}

.content_aspect {
}

.camera_frame {
    border: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.camera {
    background-color: $bg-light;
    height: 161px;
    border-radius: $border-radius-base;
    padding-top: 5px;
    position: relative;
    text-align: center;
    @include box-shadow($bxsh-camera);    

    &__no-header {
        height: 141px;
    }
    
    &__stream {
        width: 100%;
        @include aspect-ratio(16, 9);
    }
    
    &__add-icon {
        line-height: $lh-small;
        @include transform(translateY(-50%));
        @include padding(null 15px);
        @include position(absolute, 42% 0 null 0);
    }
    
    &__add-link {
        display: block;
        height: 100%;
    }
    
    &__name-text {
        line-height: $lh-small;
        @include transform(translateY(-50%));
        @include padding(null 15px);
        @include position(absolute, 50% 0 null 0);
    }
    
    &__name-text-mini {
        line-height: $lh-small;
        font-size: 14px;
        padding-top: 3px;
        text-align: center;
    }

    &__name {
        height: 50px;
        background-color: $brand-primary;
        color: $brand-secondary;
        font-size: $font-size-large;
        @include position(absolute, null 0 0 0);
        @include border-bottom-radius($border-radius-base);
        
        &-compact {
            height: 30px;
        }
        
        &-mini {
            height: 20px;
            position: relative;
        }
    }
    
    &__offline-text {
        color: $brand-danger;
        font-size: 18px;
        @include position(absolute, 25% 0 null 0);
    }
    
    .checkbox-block {        
        > input {
            opacity: 0;
            position: absolute;

            + label {
                font-size: 16px;
                margin-bottom: 0;
                min-height: $size-checkbox;
                position: relative;
                padding-top: 6px;
                padding-left: 35px;
                cursor: pointer;

                &:before {
                    border: 1px solid #fff;
                    border-radius: 6px;
                    content: '';
                    display: inline-block;
                    @include size($size-checkbox, $size-checkbox);
                    @include position(absolute, 0 null null 0);
                }
            }
        }
        
        &--checkbox > input {
            &:checked + label:before,
            &:checked:disabled + label:before {
                content: '';
                background-image: url('#{$images}/svg/icon-arrow-bottom-blue.svg');
                background-position:  50% 50%;
                background-repeat: no-repeat;
                background-size: 15px 8px;
            }

            &:checked + label:before {
                background-color: #EAEDFF;
                border-color: #AAA;
            }
        }
    }
    
    &__btn-delete svg{
        width: 12px !important;
        height: 12px !important;
    }
    
    &__btn-icon {
        padding: 0px !important;
    }
    
    &__delete {
        text-align: right;
        margin-right: 8px;
    }
    
    &__controls {
        &-paused {
            position: relative;
            cursor: pointer;
            width: 100%;

            &:after {
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                content: "\f01d";
                font-family: FontAwesome;
                font-size: 60px;
                color: #fff;
                opacity: .8;
                text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
            }
            &:hover:after {
                color: #eee;
            }
        }
        
        &-playing {
            position: relative;
            cursor: pointer;
            width: 100%;
            
            &-hover {
                position: relative;
                cursor: pointer;
                width: 100%;
                
                &:hover:after {
                    position:absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    content: "\f0b2";
                    font-family: FontAwesome;
                    font-size: 60px;
                    color: #fff;
                    opacity: .8;
                    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
                    pointer-events: none;
                }
            }
        }
    }
    
    &__pause {
        position: absolute;
        cursor: pointer;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);

        &:after {
            content: "\f28c";
            font-family: FontAwesome;
            font-size: 30px;
            color: #fff;
            opacity: .8;
            text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        }        
    }
}

#cam_fullscreen {
    position: absolute;
    top: 0; left: 0;
    width:100%;
    height:100%;
    background-color: #000;
}

.cam_fullscreen {
    &_active {
        display: block;
        z-index: 7;
    }
    
    &_inactive {
        display: none;
    }
    
    &_mobile {
        width: 100vh;
        height: 100vw;
        
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
        transform: translateY(-100%) rotate(90deg);
    }
}

.btn-primary-off {
  @include button-variant(#AAA, #fff, #AAA);
}

.btn-camera-stream {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px !important;
    
    &-first {
        @extend .btn-camera-stream;
        margin-left: 5px;        
    }
}

.alert_danger {
    border-color: #f1204c;
    background-color: #ffd1d1;
    text-align: center;
}

.btn-margin-top {
    margin-top: 20px;
}

.inlineLoader {
    border: $brand-primary solid 1px;
    background-color: lighten($brand-primary, 65%);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;
    
    svg {
        fill: $brand-primary;
        @include size(30px, 30px);
        animation: spin 1s linear infinite;
        margin-right: 10px;
        margin-top: 4px;
        margin-bottom: -2px;
    }
    
    #inlineLoader-statusText {
        position: absolute;
        top: 25%;
        color: $brand-primary;
    }
}

@media screen and (max-width: $w-page-max) {
    .camera__url-field {
        display: none;
    }
    .camera__link-text {
        display: none;
    }
    .camera__link-system {
        display: none;
    }
    .camera__popup-button-margin {
        display: none;
    }
    .camera__popup-button {
        width: 50%;
    }
    .camera__system-name {
        display: inherit !important;
    }
    .list__camera-button-container .btn--icon {
        height: $h-button;
        padding: 5px;
        margin-left: 10px;
    }
}