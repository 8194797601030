//Remove default browser outline from elements, leave only hovers
* {
    outline: 0 !important;
    outline: none !important;
}

.uppercase {
    text-transform: uppercase;
}

.h-circle {
    border-radius: 50%;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-hide {
    display: none !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-show {
    display: block !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-display-inline-block {
    display: inline-block !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-text-align-left {
    text-align: left !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-text-align-right {
    text-align: right !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-text-align-center {
    text-align: center !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-vertical-align-top {
    vertical-align: top !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-vertical-align-middle {
    vertical-align: middle !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-vertical-align-bottom {
    vertical-align: bottom !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-float-left {
    float: left !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-float-right {
    float: right !important;
}

// Leidziama naudoti
// scss-lint:disable ImportantRule
.h-box-sizing-border-box {
    box-sizing: border-box !important;
}

.uppercase {
    text-transform: uppercase;
}

.border {
    border: solid;
    border-color: $brand-primary;
    border-width: 2px;
    
    &-danger {
        @extend .border;
        border-color: $brand-danger;
    }
}

.no-pointer-events {
    pointer-events: none;
}