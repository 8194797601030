.switch {
    &__status-icon {
        @extend .area__status-icon;
        width: 60px;
        height: 60px;

        > svg {
            color: $brand-primary;
            width: 60px;
            height: 60px;
        }
    }
    
    &__status-button {
        @extend .area__status-icon;        
    }
    
    &__timer {
        font-size: $font-size-small;
        position: absolute;
        color: $brand-secondary;
        display: table-cell;
        left: 50%;
        transform: translateX(-50%);
        border: solid 1px $brand-primary;
        border-radius: 999px;
        padding-left: 5px;
        padding-right: 5px;
        background-color: $brand-primary;
        
        &--dashboard {
            @extend .switch__timer;
            top: 50%;
            transform: translate(-150%, -50%);
            display: inline-block;
            font-size: $font-size-base;
            left: auto;
        }
    }
    
    &__icon {
        > svg {
            width: 50px;
            height: 50px;
        }
        
        &--radio {
            padding: 0px;
            margin: 0px;
            position: absolute;
            top: 55%;
            left: 75%;
            transform: translateY(-50%);
        }
        
        &--big {
            > svg {
                width: 80px;
                height: 80px;
            }
            
            &.mobile {
                > svg {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
    
    &__icon-set {
        border: dashed 1px $brand-primary;
        margin-left: 10%;
        margin-right: 10%;
    
        &--bordered {
            position: relative;
            border: solid 1px $brand-primary;
            margin-bottom: 5px;
            margin-left:20px;
            margin-right:20px;
        }
    }
}