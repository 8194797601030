.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px 0 30px 0;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;

  & div.x-overflow {
    width: 100%;
    overflow-x: auto;
  }

  & table {
    width: 100%;
    line-height: inherit;
    text-align: left;

    & td {
      padding: 5px;
      vertical-align: top;
    }

    & tr {
      &.top {
        & table {
          & td {
            padding-bottom: 20px;

            @media only screen and (max-width: 600px) {
              width: 100%;
              display: block;
              text-align: center;
            }

            &.title {
              font-size: 45px;
              line-height: 45px;
              color: #333;
            }
          }
        }
      }

      &.information {
        & table {
          & td {
            padding-bottom: 40px;

            @media only screen and (max-width: 600px) {
              width: 100%;
              display: block;
              text-align: center;
            }
          }
        }
      }

      &.heading {
        & td {
          background: #eee;
          border-bottom: 1px solid #ddd;
          font-weight: bold;
          font-size: 16px;
        }
      }

      &.details {
        & td {
          padding-bottom: 20px;
        }
      }

      &.item {
        & td {
          border-bottom: 1px solid #eee;
        }

        &.last {
          border-bottom: none;
        }
      }

      &.total {
        & td:nth-child(2) {
          border-top: 2px solid #eee;
          font-weight: bold;
        }
      }
    }
  }
}

@media print {
  .header {
    display: none;
  }
  .left-menu {
    display: none;
  }
  .content-wrapper {
    padding-left: 80px !important;
  }
}

.invoice_debt {
    &--text {
        font-size: 16px;
    }
    
    &--negative {
        color: green;
    }
}