.scroll-wrapper {

    > .scroll-element {
        background-color: #fff;

        &,
        & div {
            border: none;
            margin: 0;
            padding: 0;
            position: absolute;
            z-index: 10;
        }

        div {
            display: block;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.scroll-x {
            bottom: 0;
            height: 13px;
            left: 0;
            width: 100%;

            .scroll-element_outer {
                height: 5px;
                top: 4px;
            }
        }

        &.scroll-y {
            height: 100%;
            right: 0;
            top: 0;
            width: 13px;

            .scroll-element_outer {
                left: 4px;
                width: 5px;
            }
        }

        .scroll-element_outer { overflow: hidden; }
        .scroll-element_track {
            background-color: $body-bg;
        }

        &,
        .scroll-element_outer,
        .scroll-element_track,
        .scroll-bar {
            border-radius: 3px;
        }

        .scroll-bar {
            background-color: $brand-primary;

            &:hover {
                background-color: $brand-primary;
            }
        }

        &.scroll-draggable .scroll-bar { background-color: $brand-primary; }
    }

    /* scrollbar height/width & offset from container borders */

    > .scroll-content.scroll-scrolly_visible { left: -12px; margin-left: 12px; }
    > .scroll-content.scroll-scrollx_visible { top:  -12px; margin-top:  12px; }

    > .scroll-element.scroll-x .scroll-bar { min-width: 10px; }
    > .scroll-element.scroll-y .scroll-bar { min-height: 10px; }


    /* update scrollbar offset if both scrolls are visible */

    > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -14px; }
    > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -14px; }

    > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -14px; }
    > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -14px; }
}
