.datepicker-dropdown {
    @include padding(null 10px);
}

.datepicker-ranges {
    .btn.btn-default {
        background-color: $bg-datepicker-intervals;
        border-color: $bg-datepicker-intervals;
        color: $gray-light;

        &:hover,
        &:active,
        &:active:hover,
        &:focus {
            background-color: $bg-datepicker-intervals-hover;
            border-color: $bg-datepicker-intervals-hover;
        }
    }
}

@media (max-width: 420px) {
    .datepickers-inline {
        .control-label {
            padding-right: 0;
        }

        .form-control.datepicker {
            text-align: center;
            @include padding(null 18px);
        }
    }
}
