.systemTransferHeader {
    border: 1px solid $brand-primary;
    border-bottom: none;
    padding-bottom: 8px;
    padding-top: 8px;
    background-color: lighten($brand-primary, 66%);
    z-index: 1;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.userList {
    border: 1px solid $brand-primary;
    transform: translateY(-1px);
    z-index: 0;
    margin-left: 0px;
    margin-right: -6px;
    padding: 1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: lighten($brand-primary, 66%);
}

.userItem{
    border: 1px solid $brand-primary;
    div {
        color: $brand-primary;
    }
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.deleteSystem {
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, 3px);
    border: 1px solid $brand-primary;
    text-align: center;
}

.systemItem {
    border: 2px solid $brand-primary;
    padding: 5px 2px 5px 2px;
    margin-bottom: 2px;
    position: relative;
    
    .noUsers {
        padding-top: 6px;
        color: $brand-primary;
    }
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

.systemDeleteToggler {
    padding-left: 0px;
    padding-right: 2px;
    color: $brand-primary;
}

.mini-loader {    
    svg {
        @include size(16px, 16px);
        animation: spin 750ms linear infinite;
        fill: $brand-primary;
    }
    margin: 0px;
    padding-left: 0px;
}

.toggleItem {
    cursor: pointer;
    &--toggled {          
        background-color: $brand-primary;
        color: $brand-secondary;
        > .list__sub-title {
            color: $brand-secondary;
        }
    }
}