.area {
    background-color: $bg-light;
    height: 200px;
    border-radius: $border-radius-base;
    padding-top: 25px;
    position: relative;
    text-align: center;
    @include box-shadow($bxsh-area);

    &__status {
        display: inline-block;

        &:hover,
        &:active,
        &:active:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__status-icon {
        background-size: auto $h-area-icon;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-bottom: 6px;
        @include size($w-area-icon, $h-area-icon);

        svg {
            color: $brand-primary;
            @include size($w-area-icon, $h-area-icon);
        }
    }

    &__status-icon-rotate {
        background-size: auto $h-area-icon;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-bottom: 6px;
        @include size($w-area-icon, $h-area-icon);

        svg {
            @include size($w-area-icon, $h-area-icon);
            animation: spin 1s linear infinite;
        }
    }

    &__status-name {
        color: $brand-primary;
        white-space: nowrap;
        overflow: hidden;
    }

    &__name {
        height: 78px;
        background-color: $brand-primary;
        color: $c-area-name;
        font-size: $font-size-large;
        @include position(absolute, null 0 0 0);
        @include border-bottom-radius($border-radius-base);
    }

    &__name-text {
        line-height: $lh-small;
        @include transform(translateY(-50%));
        @include padding(null 15px);
        @include position(absolute, 50% 0 null 0);
    }

    &__menu-link {
        @include padding(10px 13px);
        @include position(absolute, 0 0 null null);

        svg {
            @include size(5px, 18px);
        }
    }

    &__menu {
        background-color: $bg-light;
        border-radius: $border-radius-base;
        display: none;
        width: 125px;
        z-index: $z-menu;
        @include box-shadow($bxsh-content-desktop);
        @include position(absolute, 1px 2px null null);
    }

    &__menu-item {
        border-bottom: 1px solid $body-bg;
        display: block;
        text-align: left;
        @include padding(10px 10px 10px 25px);

        &:last-child {
            border-bottom: 0;
        }

        &:hover,
        &:active,
        &:active:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__overlay {
        display: none;
        z-index: ($z-menu - 1);
        @include position(absolute, 0 0 0 0);
    }

    &--danger {
        .area {
            &__menu-link,
            &__status-icon,
            &__status-name {
                color: $brand-danger;
            }

            &__name {
                background-color: $brand-danger;
            }
        }

        &:before {
            background-color: $bg-light;
            background-image: url('#{$images}/svg/icon_alarm.svg');
            background-position: 50% 50%;
            background-size: 6px ($h-area-danger-icon / 2);
            background-repeat: no-repeat;
            border-radius: 50%;
            content: '';
            display: inline-block;
            @include box-shadow($bxsh-area);
            @include size($w-area-danger-icon, $h-area-danger-icon);
            @include position(absolute, (-$h-area-danger-icon / 3) null null (-$w-area-danger-icon / 3));
        }
    }
}
